@import './_variables';

/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------
 */

// ~ 575px
@mixin phone {
  @media (max-width: #{$phone-max + 'px'}) {
    @content;
  }
}

// ~ 767px
@mixin tablet-or-less {
  @media (max-width: #{$tablet-max + 'px'}) {
    @content;
  }
}

// 576px ~ 767px
@mixin tablet-only {
  @media (min-width: #{$tablet-min + 'px'}) and (max-width: #{$tablet-max + 'px'}) {
    @content;
  }
}

// 576px ~
@mixin tablet-or-more {
  @media (min-width: #{$tablet-min + 'px'}) {
    @content;
  }
}

// ~ 1339px
@mixin laptop-or-less {
  @media (max-width: #{$laptop-max + 'px'}) {
    @content;
  }
}

// 768px ~ 1339px
@mixin laptop-only {
  @media (min-width: #{$laptop-min + 'px'}) and (max-width: #{$laptop-max + 'px'}) {
    @content;
  }
}

// 768px ~
@mixin laptop-or-more {
  @media (min-width: #{$laptop-min + 'px'}) {
    @content;
  }
}

// 1440px ~
@mixin desktop-or-more {
  @media (min-width: '1440px') {
    @content;
  }
}

@mixin breakpoint-up($size) {
  @media (min-width: ($size + 'px')) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: ($size + 'px')) {
    @content;
  }
}

@mixin breakpoint-between($min, $max) {
  @media (min-width: ($min + 'px')) and (max-width: ($max + 'px')) {
    @content;
  }
}
