@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
/**
 * ----------------------------------------
 * Functions
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Colors
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Fonts
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------Â
 */
/**
 * ----------------------------------------
 * Colors
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Fonts
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------Â
 */
/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------
 */
#body {
  background: #f7f7fc;
  height: 100vh;
  width: 100vw;
}
@media (max-width: 767px) {
  #body {
    width: 100vw;
    height: 100vw;
  }
}

/* .recent-alerts span {
  border-bottom:1px solid #ddd
} */
.recent-alerts .highlight {
  color: rgb(255, 255, 169);
}

#message-bar {
  bottom: 0;
  left: 0;
  position: fixed;
  font-size: 95%;
  width: 100%;
  overflow: hidden;
  background-color: #0f388a;
  color: #FFF;
  border-top: 1px solid white;
  white-space: nowrap;
  z-index: 102;
}

#message-bar-inner {
  padding: 4px;
}

#message-bar-right {
  position: absolute;
  top: 1px;
  right: 3px;
}

.aqi-bar-chart {
  max-width: 300px;
  max-height: 100px;
}

.histogram-bar-chart {
  max-width: 300px;
}

#voc-aqi h3 {
  text-transform: uppercase;
}

#histogram, #pm-100, #pm-25, #pm-10 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#histogram h3 {
  margin-bottom: 30px;
}

#histogram-labels {
  display: flex;
  font-size: 60%;
  padding: 3px;
  justify-content: space-between;
}

#events {
  width: 100%;
  height: auto;
  max-height: 255px;
  overflow-y: scroll;
  max-width: 1200px;
}

#events strong {
  font-weight: 600;
  font-size: 110%;
}

#events ul {
  padding: 10px;
}

#events ul li {
  margin-bottom: 5px;
  text-align: left;
  font-size: 90%;
}

.analysis-wrapper {
  max-width: 1200px;
  width: 100%;
  margin-bottom: 7px;
}

.analysis-chart-wrapper {
  height: 75vh;
  width: 100%;
}

#analysis-options select {
  margin-left: 10px;
}

#change-device {
  /* position: fixed;
  bottom: 19px;
  right: 0px;
  padding: 10px; */
}

#change-device select {
  font-size: 110%;
  margin-left: 5px;
}

.login-form {
  border: 1px solid #17c2c7;
  border-radius: 3px;
  background-color: #080875;
  padding: 20px 5px 10px;
  max-width: 250px;
  text-align: center;
  margin: 20px auto 10px;
}

.login-form label {
  font-size: 18px;
}

.login-form input {
  display: block;
  margin: 3px auto 10px;
}

.login-form button {
  padding: 5px;
  font-size: 110%;
}

#register, #login {
  text-align: center;
}

.error-message {
  padding: 5px;
  color: #FF8585;
  border: 1px solid red;
  border-radius: 3px;
  margin-top: 8px;
}

.good-text {
  color: #7af26f;
}

.moderate-text {
  color: #eceb7c;
}

.unhealthy-sg-text {
  color: #eccb7c;
}

.unhealthy-text {
  color: #FF8585;
}

.very-unhealthy-text {
  color: #FF8585;
}

.hazardous-text {
  color: #F28FC7;
}
