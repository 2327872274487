/**
 * ----------------------------------------
 * Colors
 * ----------------------------------------
 */

// black
$black: #181829;

// blue
$blue: #0057b7;
$light-blue: #3697ff;

// gray
$gray: #E5E5E5;

// green
$green: #20CA47;

// red
$red: #EB5757;

// white
$white: #ffffff;

// theme colors
$background: #f7f7fc;
$auth-background: #0d1031;
$primary: #002f4d;
$primary-transparent: #0808750F;
$btn-blue: #1F96FF;
$line: #EAEAEA;
$low-opacity-text: #8F8F96;
$lower-opacity-text: #c5c5ce77;
$tooltip-bg: #555;
$login-bg: #0d1031;

// temperature colors
$very-cold: #0245ff;
$cold: #05abc3;
$cool: #a2d168;
$warm: #ffdf00;
$hot: #fc7908;
$very-hot: #f40701;

/**
 * ----------------------------------------
 * Fonts
 * ----------------------------------------
 */

$dm-sans: 'DM Sans';
$sans-serif: 'sans-serif';

/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------Â
 */

$phone-min: 0;
$phone-max: 599;

$tablet-min: 600;
$tablet-max: 767;

$laptop-min: 768;
$laptop-max: 1339;

$desktop-min: 1340;