@import '../../theme/core.scss';

.mainBar {
  background: $white;
  color: $black;
  grid-area: mainBar;
  overflow: visible;
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 0;
  width: calc(100vw - 240px);
  height: 80px;
  left: 240px;
  z-index: 20;
  box-shadow: rgba(2, 8, 20, 0.05) 3px 6px 5px;

  @include tablet-or-less {
    width: 100vw;
    height: 50px;
    left: 0px;
  }

  .logoImage {
    display: none;

    @include tablet-or-less {
      display: block;
      justify-self: center;
      align-self: center;
      width: 28px;
      height: 28px;
      padding: 9px 15px;
      cursor: pointer;
    }
  }

  .navTitle {
    font-size: 24px;
    font-weight: 500;
    align-self: center;
    margin-left: 30px;
    margin-right: auto;

    @include tablet-or-less {
      font-size: 18px;
      font-weight: 500;
      margin: auto;
    }
  }

  .navIcons {
    // width: 120px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: $low-opacity-text;

    // fix with 
    .iconGroup {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      height: 100%;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }

      .iconProfile {
        margin-left: 10px;
        width: 40px;
        height: 40px;
        // color: $black;
      }

      .iconChevron {
        width: 24px;
        height: 24px;
      }

      .iconNotificationBell {
        width: 24px;
        height: 24px;
      }

      .iconNotificationDot {
        position: absolute;
        width: 8px;
        color: red;
        display: none;
        transform: translateY(-32px) translateX(20px);
      }
    }

    .mainBarButton {
      min-height: 35px;
      width: 120px;
      background: $primary;
      color: $white;
      border: none;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
      }

    }

    // .notificationsLength {
    //   position: absolute;
    //   display: none;
    //   color: $white;
    //   font-size: 6px;
    //   font-weight: 700;
    //   transform: translateY(-29px) translateX(22px);
    // }
    .hasNotifications {
      display: block;
    }

    @include tablet-or-less {
      display: none;
    }
  }

  .iconMobileMenu {
    display: none;

    @include tablet-or-less {
      display: block;
      color: $low-opacity-text;
      height: 26px;
      width: 26px;
      padding: 12px 18px;
      cursor: pointer;
    }
  }
}

.navBar {
  background: $white;
  color: $black;
  grid-area: navBar;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 240px;
  height: 100%;
  z-index: 10;
  box-shadow: rgba(2, 8, 20, 0.05) 1px 0px 5px;

  @include tablet-or-less {
    display: none;
    grid-area: none;
  }

  .logoImage {
    height: 45px;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  nav {
    background: $white;
    color: $black;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 15px;

    .navLink {
      min-height: 55px;

      color: $low-opacity-text;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      border-radius: 10px 0px 0px 10px;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 20px;
      gap: 10px;

      .navLinkIcon {
        width: 24px;
        height: 24px;
      }
    }

    .active {
      background: $primary;
      color: $white;
    }
  }

  .appVersionNumberLabel {
    margin-top: auto;
    margin-bottom: 15px;
    align-self: center;
    font-size: 11px;
  }
}

.mobileNavBar {
  display: flex;
  overflow: hidden;
  background: $white;
  color: $black;
  grid-area: navBar;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 10;

  @include laptop-or-more {
    display: none;
    grid-area: none;
  }

  .navProfileGroup {
    // width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    margin-left: 30px;
    text-decoration: none;
    color: $primary;

    .iconProfile {
      width: 100px;
      height: 100px;
    }

    .iconEditProfile {
      width: 30px;
      height: 30px;
      transform: translateX(-10px)
    }
  }

  .navLinkGroup {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 15px;

    .navLink {
      min-height: 55px;
      color: $low-opacity-text;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      border-radius: 10px;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 20px;
      gap: 10px;

      .navLinkIcon {
        width: 24px;
        height: 24px;
      }
    }

    .active {
      background: $primary;
      color: $white;
    }
  }

  .mobileProfileLinks {
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    // gap: 50px;
    button {
      &:hover {
        cursor: pointer;
      }
    }

    .profileMenuLink {
      min-height: 35px;
      width: 120px;
      background: $primary;
      color: $white;
      border: none;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }

    .signoutButton {
      background-color: $red;
    }
  }

  .appVersionNumberLabel {
    // margin-top: auto;
    margin-bottom: 60px;
    align-self: center;
    font-size: 11px;
  }
}

.mobileNavBarOpen {
  transform: translateY(calc(0% + 50px));
  transition: transform 0.75s ease;
}

.mobileNavBarClosed {
  transform: translateY(-100%);
  transition: transform 0.5s ease;
}

.profileMenu {
  width: 160px;
  height: 240px;
  background: $white;
  color: $black;
  position: fixed;
  z-index: 10;
  right: 0;
  border-radius: 0px 0px 0px 10px;
  box-shadow: rgba(2, 8, 20, 0.05) -2px 2px 5px;
  display: flex;
  flex-direction: column;
  padding-top: 12px;

  @include tablet-or-less {
    display: none;
  }

  .profileMenuDivideLine {
    height: 4px;
    width: 100%;
    background: $line;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 15px;
    border-radius: 5px 0px 0px 5px;
  }

  .profileMenuLinkGroup {
    width: 100%;
    background: $white;
    color: $black;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 15px;

    .profileMenuLink {
      min-height: 35px;
      background: $white;
      color: $low-opacity-text;
      border: none;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      border-radius: 10px 0px 0px 10px;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 20px;
      gap: 10px;
      cursor: pointer;

      &:hover {
        background: $primary;
        color: $white;
      }

      .navLinkIcon {
        width: 24px;
        height: 24px;
      }
    }

    .signoutButton {
      &:hover {
        background-color: $red;
      }
    }
  }
}

.profileMenuOpen {
  transform: translateY(calc(0% + 80px));
  transition: transform 0.75s ease;
}

.profileMenuClosed {
  transform: translateY(-100%);
  transition: transform 0.5s ease;
}