@import '../../theme/core.scss';

.spinner {
  color: #bbb;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin:-40px 0 0 -40px;
  -webkit-animation:spin 3s linear infinite;
  -moz-animation:spin 3s linear infinite;
  animation:spin 3s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}


